<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div style="">

        <v-row>
            <v-col>
                <v-container class="my-5">
                    <v-row class="px-3">
                        <v-col cols="0" md="2">
                            <admin-main-menu></admin-main-menu>
                        </v-col>
                        <v-col cols="12" md="9">
                            <v-row>
                                <v-col cols="12" class="sub-title-3">
                                    전자결제정보
                                </v-col>
                                <table class="table-left-s">
                                    <colgroup>
                                        <col style="width:25%"/>
                                        S
                                        <col style="width:75%"/>
                                    </colgroup>
                                    <tr>
                                        <th class="th">결제방법</th>
                                        <td class="td">
                                            <input type="checkbox" id="checkbox1" v-model="payInfo.pgMethod.isMethod0"
                                                   class="checkbox-custom-admin">
                                            <label for="checkbox1" class="label-custom-admin">무통장</label>
                                            <input type="checkbox" id="checkbox2" v-model="payInfo.pgMethod.isMethod1"
                                                   class="checkbox-custom-admin">
                                            <label for="checkbox2" class="label-custom-admin">계좌이체</label>
                                            <input type="checkbox" id="checkbox3" v-model="payInfo.pgMethod.isMethod2"
                                                   class="checkbox-custom-admin">
                                            <label for="checkbox3" class="label-custom-admin">카드</label>

                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="th">비밀번호</th>
                                        <td class="td-phone" style="display: flex;">
                                            <select v-model="payInfo.isPw" style="margin-left:3px;">
                                                <option value=true>사용</option>
                                                <option value=false>미사용</option>
                                            </select>
                                            <span style="line-height: 2.5; margin-left: 7px;">
                                            * 고객 예약/조회시 비밀번호 확인여부
                                                </span>

                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="th">전자결제사</th>
                                        <td class="td-phone">
                                            <input id="usrMail" v-model="payInfo.pgNm">

                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="th">전자결제사 상점ID</th>
                                        <td class="td-phone">
                                            <input id="usrAddr" v-model="payInfo.pgId" style="width: 100%">

                                        </td>
                                    </tr>
                                </table>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="sub-title-3 mt-4">
                                    무통장 계좌정보
                                    <v-btn
                                            color="primary"
                                            class="mx-4 white--text center-block float-right"
                                            small
                                            @click="btnClick(3)"
                                    >
                                        계좌추가
                                    </v-btn>
                                </v-col>
                                <div class="overflow-x-auto" style="width: 100%;">
                                    <table class="table-top-s" style="min-width: 500px;">
                                        <colgroup>
                                            <col style="width:15%"/>
                                            <col style="width:15%"/>
                                            <col style="width:45%"/>
                                            <col style="width:10%"/>
                                            <col style="width:10%"/>
                                        </colgroup>
                                        <tr>
                                            <th>은행명</th>
                                            <th>예금주</th>
                                            <th>계좌번호</th>
                                            <th style="text-align: center;">수정</th>
                                            <th style="text-align: center;">삭제</th>
                                        </tr>
                                        <tr
                                                v-for="(item,i) in accList"
                                                :key="i">
                                            <td>{{item.accBnkNm}}</td>
                                            <td>{{item.accResvNm}}</td>
                                            <td>{{item.accNum}}</td>
                                            <td>
                                                <v-btn
                                                        color="primary"
                                                        class="mx-4 white--text center-block"
                                                        small
                                                        @click="btnClick(0,item)"
                                                >
                                                    수정
                                                </v-btn>
                                            </td>
                                            <td>
                                                <v-btn
                                                        color="error"
                                                        class="mx-4 white--text center-block"
                                                        small
                                                        @click="btnClick(1,item)"
                                                >
                                                    삭제
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </table>
                                </div>

                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-btn
                                            color="primary"
                                            class="mx-4 white--text center-block"
                                            small
                                            @click="btnClick(2)"
                                    >
                                        저장
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>

        <!-- 다이얼로그 -->
        <v-row justify="center">
            <v-dialog
                    v-model="dialog_acc"
                    max-width="450px"
            >

                <v-card>

                    <v-card-text>
                        <v-row>
                            <v-col cols="12" class="">
                                <v-col cols="12" class="reservation-select-title mt-6">
                                    계좌정보
                                </v-col>
                                <v-col cols="12">
                                    <table class="table-left-s">
                                        <colgroup>
                                            <col style="width:30%"/>
                                            <col style="width:70%"/>
                                        </colgroup>
                                        <tr>
                                            <th class="th">계좌ID</th>
                                            <td class="td">
                                                <input id="accId" v-model="accInfo.accId" placeholder="자동으로 생성"
                                                       readonly>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th">은행명</th>
                                            <td class="td">
                                                <input id="accBnkNm" v-model="accInfo.accBnkNm">
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th">예금주</th>
                                            <td class="td">
                                                <input id="accResvNm" v-model="accInfo.accResvNm">
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th">계좌번호</th>
                                            <td class="td">
                                                <input id="accNum" v-model="accInfo.accNum">
                                            </td>
                                        </tr>
                                    </table>
                                </v-col>
                            </v-col>
                        </v-row>
                        <v-row>
                            <div style="margin: 0 auto;">
                                <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="dialog_acc = false"
                                >
                                    취소
                                </v-btn>
                                <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="btnClick(4)"
                                >
                                    저장
                                </v-btn>
                            </div>
                        </v-row>

                    </v-card-text>
                    <v-card-actions>

                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>

    </div>
</template>

<script>
    import AdminMainMenu from '../../components/AdminMainMenu'

    export default {
        name: 'AdminPayInfo',
        components: {
            AdminMainMenu
        },
        data: () => ({
            usrInfo: {
                usrId: "",
                usrPw: "",
                usrPwConfirm: "",
                usrTel: "",
                usrMail: "",
                usrAddr: "",
            },
            isPwConfirm: false,
            accList: [
                {
                    accId: 1,  //번호
                    accBnkNm: "농협",   //은행명
                    accResvNm: "최정자",  //예금주
                    accNum: "352-0170-6948-03", // 계좌번호
                }
            ],
            accInfo: {
                isAdd: false,
                addId: 0,
                accBnkNm: "",   //은행명
                accResvNm: "",  //예금주
                accNum: "", // 계좌번호
            },
            dialog_acc: false,
            payInfo: {
                pgMethod: {
                    isMethod0: false,
                    isMethod1: false,
                    isMethod2: false,
                },
                pgNm: "",
                pgId: "",
                isPw: false,
            },
            payMethod: {
                isMethod0: false,
                isMethod1: false,
                isMethod2: false,
            }


        }),
        created: function () {
            // 맨위로
            window.scrollTo(0, 0);
            //this.$isAdminCheck(this.$store.state.admin.isLogin);
            //this.$isAuthCheck(this.$getCookie("AUTH"));
            this.getData();


        },
        methods: {
            getData() {
                return this.$store.dispatch("admin/getComn", {comnGubun: 'accList'})
                    .then((resp) => {
                        setTimeout(() => {

                            if (resp.message !== undefined && resp.message.comnCont !== undefined) {
                                this.accList = JSON.parse(resp.message.comnCont);
                            }

                            return this.$store.dispatch("admin/getComn", {comnGubun: 'payInfo'})
                                .then((resp) => {
                                    setTimeout(() => {

                                        if (resp.message !== undefined && resp.message.comnCont !== undefined) {
                                            this.payInfo = JSON.parse(resp.message.comnCont);
                                        }


                                    }, 300)
                                })
                                .catch((err) => {
                                })


                        }, 300)
                    })
                    .catch((err) => {
                    })

            },

            btnClick(val, item) {
                let _this = this;

                switch (val) {
                    case 0: {
                        this.accInfo = item;
                        this.accInfo.isAdd = false;
                        this.dialog_acc = true;
                        break;
                    }
                    case 1: {
                        if (!confirm("계좌정보를 삭제 하시겠습니까?")) {
                            // 취소(아니오) 버튼 클릭 시 이벤트
                        } else {
                            // 확인(예) 버튼 클릭 시 이벤트
                            _this.accList.forEach(function (item1, index, arr2) {
                                if (item1.accId !== undefined && item.accId !== undefined) {
                                    if (item1.accId === item.accId) {
                                        _this.accList.splice(index, 1);
                                    }
                                }
                            });

                        }
                        break;
                    }
                    case 2 : {


                        // true String to boolean
                        this.payInfo.isPw = JSON.parse(this.payInfo.isPw);


                        let formdata_pay = {
                            comnGubun: "payInfo",
                            comnNm: "전자결제정보",
                            comnCont: JSON.stringify(this.payInfo),
                            lstModPrs: this.$store.state.admin.loginInfo.usrId
                        };

                        let formdata_acc = {
                            comnGubun: "accList",
                            comnNm: "계좌정보",
                            comnCont: JSON.stringify(this.accList),
                            lstModPrs: this.$store.state.admin.loginInfo.usrId
                        };

                        this.$store.dispatch("admin/updateComn", formdata_pay)
                            .then((resp) => {
                                setTimeout(() => {

                                    this.$store.dispatch("admin/updateComn", formdata_acc)
                                        .then((resp) => {
                                            setTimeout(() => {

                                                alert("저장완료");
                                                setTimeout(() => {
                                                    location.href = "/admin/payInfo"
                                                }, 300)

                                            }, 300)
                                        })
                                        .catch((err) => {
                                        })

                                }, 300)
                            })
                            .catch((err) => {
                            })

                        break;
                    }
                    case 3 : {
                        this.accInfo = {};
                        this.accInfo.isAdd = true;
                        if (this.accList.length === 0) {
                            this.accInfo.accId = 1;
                        }
                        this.dialog_acc = true;
                        break;
                    }
                    case 4: {
                        //let _this = this;
                        if (_this.accInfo.isAdd) {
                            _this.accList = _this.$sortList(_this.accList, "accId");
                            let accId = this.accInfo.accId;

                            if (this.accList.length === 0) {
                                //this.accInfo.accId = 1;
                            } else {
                                accId = (_this.accList[_this.accList.length - 1].accId) + 1;
                            }
                            _this.accInfo.accId = accId;
                            _this.accList.push(_this.accInfo);
                            _this.dialog_acc = false;

                        } else {

                            _this.accList.forEach(function (item, index, arr2) {

                                if (_this.accList[index].accId != undefined && _this.accInfo.accId != undefined) {
                                    if (_this.accList[index].accId === _this.accInfo.accId) {

                                        _this.accList[index] = _this.accInfo;
                                        _this.dialog_acc = false;
                                    }
                                }

                            });

                        }

                        break;
                    }
                }

            },
            menuClick(route) {
                location.href = route;
                //this.$router.push({path: route});
            }

        },
        mounted() {
            this.$nextTick(() => {

            })
        },
        computed: {},
    }
</script>

<style>

</style>
